<!-- IMPORTANT:	Displayed version is determined by a GET parameter. Currently "?pub" loads only serial and part number search so 
				that dealer version is default. When deployed to supreme site the parameters need to be swapped to "?dealer" so 
				that the public version is default. This will also need to be added to both serial search and parts search 
				components for copyLink() to make sure created links are always public. -->

<template>
	<div 
		class="core"
		v-resize="onResize">
		<!-- Update popup -->
		<div class="update-container"
			v-if="showUpdate"
			@click="showUpdate = false">
			<v-spacer />

			<div class="update-inner">
				<div class="close-button">
					<v-btn
						color="default"
						icon
						@click="showUpdate = false">
						<v-icon
							large
							color="error">
							mdi-close-circle
						</v-icon>
					</v-btn>
				</div>
				<update />
			</div>

			<v-spacer />
		</div>

		<!-- Main Page Title Component -->
		<div class="title center">
			<div class="title-image-container">
				<img
					class="title-image" 
					src="../assets/SUPREME-LOGO.png" />
			</div>
			<v-spacer />
			<h3>Supreme International Dealer Tools</h3> &nbsp; <span class="version">V {{ currVersion }}</span>
			<v-spacer />
		</div>

		<v-tabs
			class="tabs"
			v-model="tab"
			height="25px"
			color="white"
			dark
			ripple
			grow>
			<v-tabs-slider color="red" />

			<v-tab
				v-for="item in tabs"
				:key="item.name">
				{{ item.name }}
			</v-tab>

			<v-tabs-items
				v-model="tab">
				<v-tab-item
					v-for="item in tabs"
					:key="item.name"
					eager>
					<div 
						class="tab-wrapper">
						<component
							:ref="item.ref" 
							:is="item.component"
							@showUpdate="showUpdate = true"
							@part-clicked="partClicked"/>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { version } from '../../package.json'		// Imported current version build from package.json.
require("@/assets/dealer_background.jpg")

// Imported vuejs components
import Update from './update'						// Imports update component which is used as help and version information.
import SerialSearch from './serial_search'			// Imports Serial Number Search component which adds part searching functionality.
import PartSearch from './part_search'				// Imports Parts Search component which adds part searching functionality.
import Documents from './documents'					// Imports Docuemnts component which loads commonly used PDFs.
import Links from './links'							// Imports Links component which loads links to commonly used URLs.

export default {
	name: 'Core',
	components: {
		Update,
		PartSearch,
		SerialSearch,
		Documents,
		Links,
	},
	data () {
		return {
			/* Version data used to decide whether to show update component. */
			currVersion: version,		// Current version set from package.json.
			lastVersion: '',			// Last version loaded by user. Set by reading cookie.
			showUpdate: false,			// Toggles update visibility through version mismatch or "Help" button.

			// Tab Varaibles
			tab: 0,
			pubTabs: [
				{
					name: 'Serial Number Search',
					component: 'serial-search',
					ref: 'serial'
				},
				{
					name: 'Part Number Search',
					component: 'part-search',
					ref: 'part'
				}
			],
			dealerTabs: [
				{
					name: 'Serial Number Search',
					component: 'serial-search',
					ref: 'serial'
				},
				{
					name: 'Part Number Search',
					component: 'part-search',
					ref: 'part'
				},
				{
					name: 'Documents',
					component: 'documents',
					ref: 'documents'
				},
				{
					name: 'Links',
					component: 'links',
					ref: 'links'
				}
			],
			tabs: [],
		}
	},
	computed: {
	},
	methods: {
		onResize() {
			this.$el.style.height = window.innerHeight + "px"
			this.$el.style.maxHeight = window.innerHeight + "px"
		},
		partClicked(id, part) {
			this.tab = 1

			this.$refs.part[0].partNumber = part
			this.$refs.part[0].searchPart(id)
		},
	},
	created() {
		if (this.$cookie.get("version")){
			this.lastVersion = this.$cookie.get("version")
		}

		if (this.lastVersion.length > 0 ) {
			if (this.lastVersion != this.currVersion) {
				this.$cookie.set("version", this.currVersion, 20*365)
				this.showUpdate = true
			}
		} else {
			this.$cookie.set("version", this.currVersion, 20*365)
			this.showUpdate = true
			console.log("No version cookie found. Create new one.")
		} 
	},
	mounted: function() {
		this.$el.style.height = window.innerHeight + "px"
		this.$el.style.maxHeight = window.innerHeight + "px"

		let urlParams = new URLSearchParams(window.location.search)

		// If the page is live on supremeinternational.com set config.json "version": "live" and "dealerParam": "dealer" for default to be public.
		if (this.$config.version == "live") {
			if(urlParams.has(this.$config.dealerParam) && this.$config.dealerParam == "dealer"){
				this.tabs = this.dealerTabs
			}

			if (this.tabs.length == 0){
				this.tabs = this.pubTabs
			}
		} 
		
		// If the page is in testing environment set config.json "version": "test" and "dealerParam": "pub" for default to be dealer.
		if (this.$config.version == "test") {
			if(urlParams.has(this.$config.dealerParam) && this.$config.dealerParam == "pub"){
				this.tabs = this.pubTabs
			}

			if (this.tabs.length == 0){
				this.tabs = this.dealerTabs
			}
		}

		if(urlParams.has('part')){
			if (urlParams.get('part').length > 0){
				this.tab = 1
			}
		}

		if(urlParams.has('serial')){
			if (urlParams.get('serial').length > 0){
				this.tab = 0
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.core {
		position: absolute;
		display: flex;
		flex-flow: column;
		width: 100%;
		height: 400px;
		overflow: hidden;
		background: rgba(0,0,0,0);
	}

	.tab-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		width: 100%;
		height: 400px;
		background: rgba(0,0,0,0);	
	}

	.update-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background: rgba(0,0,0,.25);
		text-align: center;
	}

	.update-inner {
		height: 700px;
		width: 1000px;
	}

	.close-button {
		position: relative;
		float: right;
		border-radius: 5px;
		top:42px;
		right: 10px;
	}

	.centered {
		text-align: center;
	}

	.title {
		flex-shrink: 1;
		font-size: 32pt;
		width: 100%;
		min-height: 80px;
		padding: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #cf2e2e;
		color: white;
	}

	.title-image {
		max-height: 100%;
		object-fit: contain;
	}

	.title-image-container {
		position: absolute;
		height: 70px;
		left: 5px;
		top: 5px;
	}

	.version {
		position: absolute;
		right: 10px;
		top: 50px;
		font-size: 8pt;
	}

	.tab-wrapper {
		height: calc(100vh - 115px);
		margin: 5px;
		background: rgba(0,0,0,0.1);
	}

	.v-window-item {
		background-image: url("@/assets/dealer_background.jpg");
		background-size:     cover;
		background-repeat:   no-repeat;
		background-position: left center;
	}
</style>
