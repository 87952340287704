<template>
	<div class="part-search">
		<!-- Multiple Search Options Modal -->
		<div 
			class="options-container"
			v-if="results.length > 0">
			<v-spacer />
			
			<div class="options-inner elevation-6">
				<div class="options-title">
					Multiple or partial matches. Please select one:

					<div class="close-button">
						<v-btn
							color="default"
							icon
							@click="results = []">
							<v-icon
								large
								color="error">
								mdi-close-circle
							</v-icon>
						</v-btn>
					</div>
				</div>

				<div class="options-results">
					<div 
						class="options-result"
						v-for="option, index in results"
						:key="index"
						@click="searchPart(option.id)">
						<div class="option-part">
							{{ option.part }}
						</div>
						 
						<div class=" option-description">
							{{option.description }}
						</div>
					</div>
				</div>
			</div>

			<v-spacer />
		</div>

		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<div class="search-bar">
			<div class="search center">
				<v-combobox
					class="fields"
					v-model="partNumber"
					:items="partNumbers"
					label="Part Number"
					ref="partInput"
					hide-details="auto"
					outlined
					dense
					clearable
					@keyup.enter.native="getParts"/>
			</div>

			<div class="search-button center">
				<v-btn
					class="button"
					color="primary"
					:disabled="!canSearch"
					@click="getParts">
					Search
				</v-btn>			

				<v-btn
					v-show="partInfo. part != null && partInfo.part.length > 0"
					class="button"
					color="success"
					@click="copyLink()">
					Copy Link
				</v-btn>

				<v-btn
					class="button"
					color="teal"
					dark
					@click="showUpdate()">
					Help
				</v-btn>

				<span 
					class="discontinued"
					style="margin-left: 5px;"
					v-if="this.error.length > 0">
					{{this.error}}
				</span>
			</div>
		</div>

		<div 
			class="part-info"
			:style="background"
			v-if="partInfo.part != null && partInfo.part.length > 0">
			<!-- Part Information Component -->
			<div class="top-row">
				<div class="preview-image-box">
					<img
						class="preview-image" 
						:src="imageAddress"
						v-if="partInfo.image.length > 0" 
						height="390x" 
						width="390px" />

					<img
						class="preview-image" 
						src="../assets/No_Image_Available.jpg"
						v-if="partInfo.image.length == 0" 
						height="390x" 
						width="390px" />
				</div>

				<div class="part-details">
					<div class="title">Part Information:</div>

					<!-- Part Number Section -->
					<div class="info-line">
						<div class="line-title">
							Part Number:
						</div>

						<div class="line-info">
							<span :class="sellable">{{ this.partInfo.part }}</span>&nbsp;<span :class="sellable" v-if="partInfo.sellable == 0">*NON-SELLABLE PART*</span>
						</div>
					</div>

					<!-- Description Section -->
					<div class="info-line">
						<div class="line-title">
							Description:
						</div>

						<div class="line-info">
							{{ this.partInfo.description }}
						</div>
					</div>

					<!-- Supersede Section -->
					<div class="info-line">
						<div class="line-title">
							Superseded By:
						</div>

						<div class="line-info">
							<div										
								v-for="supersede, index in partInfo.supersedes"
								:key="supersede.part + '_' + index"
								@click="partClicked(supersede.id, supersede.part)">
								<v-tooltip
									right
									color="rgba(0,0,0,0)">
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on">
											<span v-if="index > 0">,&nbsp;</span>	
											<span :style="sellableStyle(supersede.sellable)">{{ supersede.part }}</span>
										</div>
									</template>

									<div class="column">
										<!-- Part description display if sellable -->
										<span 
											v-if="sellable"
											style="font-size: 14pt;color: black; max-width: 1000px;">
											{{ supersede.part }} - {{ supersede.description }}
										</span>

										<!-- Part description display if non-sellable -->
										<span
											v-if="!sellable"
											style="font-size: 14pt;color: red; max-width: 1000px;">
											{{ supersede.part }} is not available for purchase.
										</span>

										<!-- Part image display if an image is set -->
										<img
											v-if="supersede.image.length > 0"
											class="image-preview" 
											:src="supersedeImageAddress(supersede.image)" 
											height="500px" 
											width="500px" />

										<!-- Part image display if no image is set -->
										<img
											v-if="supersede.image.length == 0"
											class="image-preview" 
											src="../assets/No_Image_Available.jpg" 
											height="500px" 
											width="500px" />

										<!-- Part notes display if notes are set -->
										<div v-if="supersede.notes != null && supersede.notes.length > 0">
											<span style="font-size: 14pt;color:black; text-align: left; width: 100%;">Parts Notes:</span>

											<v-divider />

											<div 
												class="notes-preview">
												<span v-html="supersede.notes"></span>
											</div>
										</div>
									</div>								
								</v-tooltip>
							</div>
						</div>
					</div>

					<!-- Parts Notes Section -->
					<div class="parts-notes">
						<div class="title">Part Notes:</div>

						<div 
							class="part-note"
							:style="hasNotes">
							<span v-html="partInfo.notes"></span>
						</div>
					</div>
				</div>
			</div>

			<!-- Part BOM Component -->
			<div class="bom">
				<div class="title">
					Sub-Components:
				</div>

				<div
					class="bom-components"
					v-if="partInfo.bom != null || partInfo.bom.length > 0">
					<div class="result-row elevation-6" style="background-color: #b3f0ff; border: 1px solid darkgray;">
						<div class="result-line">
							<div class="result-part centered">
								<h4>Part Number</h4>
							</div>

							<div class="result-quantity centered">
								<h4>Quantity</h4>
							</div>
							
							<div class="result-description centered">
								<h4>Description</h4>
							</div>
							
							<div class="result-edited centered">
								<h4>Last Edited</h4>
							</div>
						</div>
					</div>

					<div 
						class="title-full"
						v-if="partInfo.bom != null && partInfo.bom.length == 0">
							<v-spacer />

							There are no sub-components currently configured for this part.

							<v-spacer />
					</div>

					<div 
						class="top-level elevation-6"					
						v-for="bompart, index in partInfo.bom"
						:key="bompart.part + '_' + index">
						<component
							:is="'Result'"
							:item="bompart"
							:level="0"
							:nextLevel="1">
						</component>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios' // Axios is used for http async/promise api functionality.

import Result from './result' // Imports result component used to display parts and bom results.

export default {
	name: 'Empty',
	components: {
		Result
	},
	props: {
	},
	data() {
		return {
			error: '',
			loading: false,
			test: "TEST STRING",
			
			partNumber: "",
			partNumbers: [],
			partInfo: {},

			results: [],
		}
	},
	methods: {
		getParts() {
			if (!this.canSearch) {
				return
			}

			this.results = []
			this.loading = true;
			this.partNumber = this.partNumber.trim().toUpperCase()
			this.error = ""
			this.clear()

			axios.get(`${this.$config.api}/api.php?cmd=getParts&part=${this.partNumber}`)
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = "* No results: Part Number may be incomplete or currently unavailable."
						return
					}

					//console.log(response.data)
					this.clear()
					
					// If there is only one result, no need to make the user pick it. Just go straight to loading it.
					if (response.data.results.length == 1) {
						this.searchPart(response.data.results[0].id)
						return				
					}

					//Multiple Results load results variable to show selector modal
					this.results = response.data.results
					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		searchPart(id) {
			if (!this.canSearch) {
				return
			}

			this.results = []
			this.loading = true;
			this.partNumber = this.partNumber.trim().toUpperCase()
			this.error = ""
			this.clear()

			axios.get(`${this.$config.api}/api.php?cmd=searchPart&id=${id}`)
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = `* No results: Database issue: Part Identifier not found.`
						console.log(this.error)
						return
					}

					//console.log(response.data.result)
					this.clear()

					//Multiple Results load results variable to show selector modal
					this.partInfo = response.data.result

					if (!this.partNumbers.includes(this.partInfo.part)){
						this.partNumbers.unshift(this.partInfo.part)
						
						if (this.partNumbers.length > 10){
							this.partNumbers = this.partNumbers.slice(0, 10)
						}
					}


					this.loading = false

					this.$refs.partInput.blur()
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		showUpdate() {
			this.$emit('showUpdate', true)
		},
		clear() {
			this.partInfo = {}
			this.results = []
		},
		copyLink() {
			var link = `${this.$dealer}?part=${this.partInfo.part}`

			navigator.clipboard.writeText(link)

			alert(`Link to part search created and copied to clipboard`)
		},
		sellableStyle(sellable) {
			if (sellable == 1) {
				return "color: black;cursor: pointer;"
			}

			return "color: red;cursor: pointer;"
		},
		supersedeImageAddress(image){
			return `${this.$config.images}${image}`
		},
		partClicked(id, part) {
			if (part.length > 0){
				this.searchPart(id)
			}
		},
	},
	computed: {
		canSearch() {
			if (this.partNumber.length >= 2){
				return true
			}

			return false
		},
        imageAddress() {
            if (this.partInfo.image.length > 0){
                return `${this.$config.images}${this.partInfo.image}`
            }

            return ''
        },
		sellable() {
			if (this.partInfo.sellable == 0) {
				return "discontinued"
			}

			return ""
		},
		hasNotes() {
			if (this.partInfo.notes.length > 0){
				return ""
			}

			return "background-color: whitesmoke;"
		},
		background() {
			if (this.partInfo.part != null && this.partInfo.part.length == 0) {
				return ""
			}

			return "background-color: white;"
		},
	},
	mounted: function() {
		let urlParams = new URLSearchParams(window.location.search)

		if(urlParams.has('part')){
			this.partNumber = urlParams.get('part')

			if(this.partNumber.length > 0){
				this.getParts()
			} else {
				console.log(`Invalid value for part parameter: ${this.partNumber}`)
				this.partNumber = ''
			}
		}
	},	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-search {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.discontinued {
		color: red;
	}

	.search-bar {
		display: flex;
		align-items: center;
		flex-grow: 0;
		border: 1px solid silver;
		border-radius: 5px;
		padding: 5px;
		height: 50px;
		width: 100%;
		background-color: white;
	}

	.part-info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		border: 1px solid darkgrey;
		border-radius: 5px;
		margin-top: 5px;
		padding: 5px;
		overflow: hidden;
	}

	.field {
		max-width: 250px;
	}

	.button {
		margin-left: 10px;
	}

	.options-container {
		display: flex;
		align-items: center;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background-color: rgba(0,0,0,.25);
		text-align: center;
	}

	.options-inner {
		display: flex;
		flex-direction: column;
		max-height: 400px;
		width: 700px;
		overflow: hidden;
		border: 1px solid black;
		border-radius: 5px;
		background-color: white;
	}

	.close-button {
		float: right;
		top:5px;
		right: 0px;
	}

	.options-title {
		font-size: 18pt;
		flex-shrink: 1;
		background-color: lightblue;
		border-bottom: 1px solid black;
	}

	.options-results {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		border: 1px solid darkgrey;
		margin: 5px;
		padding: 5px;
		overflow-y: scroll;
	}

	.options-result {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid silver;
		width: 100%;
	}

	.options-result:hover {
		border: 1px solid lightgreen;
		background-color: lightcyan;
		cursor: pointer;
	}

	.option-part {
		padding: 5px;
		width: 20%;
		border-right: 1px solid silver;
		text-align: left;
	}

	.option-description {
		padding: 5px;
		width: 80%;
		text-align: left;
	}

	.top-row {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
		width: 100%;
		height: 400px;
	}
	.preview-image {
		object-fit: contain;
	}

	.preview-image-box {
		display: flex;
		align-items: center;
		text-align: center;
		height: 400px;
		width: 400px;
		padding: 5px;
		margin-right: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
	}

	.part-details {
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
	}

	.title {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 100%;
		height: 41px;
		background-color: aliceblue;
		font-size: 24pt;
		border: 1px solid black;
	}

	.title-full {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 100%;
		height: 100%;
		background-color: aliceblue;
		font-size: 24pt;
		border: 1px solid black;
	}

	.info-line {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 100%;
		height: 32px;
	}

	.line-title {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 150px;
		height: 100%;
		background-color: lightcyan;
		border: 1px solid darkgrey;
	}

	.line-info {
		display: flex;
		align-items: center;
		flex-grow: 1;
		padding: 5px;
		height: 100%;
		border: 1px solid darkgrey;
	}

	.parts-notes {
		flex-grow: 1;
		margin-top: 5px;
	}

	.part-note {
		margin-top: 5px;
		padding: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
		background-color: antiquewhite;
		height: 200px;
		width: 100%;
	}

	.bom {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 5px;
		border: 1px solid darkgrey;
		overflow: hidden;
	}

	.bom-components {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.top-level {
		margin-top: 5px;
	}

	.result-line {
		display:flex;
		flex-direction: row;
		width: 100%;
		min-height: 25px;
		background-color: 80b3ff;
	}

	.result-part {
		width: 30%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-quantity {
		width: 5%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-description {
		width: 55%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-edited {
		width: 10%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.centered {
		text-align: center;
	}

	.no-bom {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid darkgrey;
		width: 100%;
		height: 100%;
	}

	.notes-preview {
		border: 1px solid darkgrey;
		border-radius: 5px;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		min-height: 100px;
		max-height: 300px;
		background-color: lightyellow;
		color: black;
		font-size: 18pt;
		max-width: 1000px;
	}

	.notes {
		border: 1px solid black;
		border-left: 2px solid black;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		max-height: 60px;
		min-height: 30px;
		background-color: lightyellow;
		color: black;
		font-size: 12pt;
		font-weight: 400;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 2px solid darkgrey; 
		border-radius: 10px;
		padding: 5px;
		background-color: white;
	}

	.title2 {
		font-size: 24pt;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.search-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		padding-left: 5px;
		padding-right: 5px;
	}

</style>