<template>
	<div>
		<div class="serial-decoder"
			v-if="serial.length > 0">
			<div class="output">
				<b>Unit:</b>&nbsp;{{ this.year }} {{ this.size }} {{ this.type }} {{ this.family }} <span v-if="this.subType.length > 0">: {{ this.subType }}</span>
			</div>

			<div class="output">
				<b>Built:</b>&nbsp;#{{ this.sequence }} of {{ this.year }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SerialDecoder',
	components: {
	},
	props: {
		ser: String
	},
	data() {
		return {
			serial: "",
			family: "",
			year: "",
			size: "",
			type: "",
			subType: "",
			sequence: "",

			log: "",
		}
	},
	methods: {
		getType(type) {
			switch(type) {
				case "S": 		return "Single Pull-Type"
				case "T":		return "Twin Pull-Type"
				case "TR":		return "Triple Pull-Type"
				case "MTM":		return "Mechanical Truck Mount"
				case "TM":		return "Gen 2 Hydrostatic Truck Mount"
				case "TM2":		return "Gen 2 Hydrostatic Truck Mount"
				case "TM3":		return "Gen 3 Hydrostatic Truck Mount"
				case "SM":		return "Single Mechanical Stationary"
				case "TSM":		return "Twin Mechanical Stationary"
				case "ST":		return "Stationary"
				case "SP": 		return "SPSL"
				case "TRSM":	return "Triple Mechanical Stationary"
				case "TSH":		return "Twin Hydrostatic Stationary"
				case "DBPT":	
					this.subType = ""
					return "Delivery Box Pull-Type"
				case "DB":		
					this.subType = ""
					return "Truck Mount Delivery Box"
				default: 			return "Unknown"
			}
		},
		getSequence(sequence){
			sequence = sequence.replace(/^0+/, "")

			return sequence
		},
		getSubType(type){
			switch(type){
				case "D": 	return "Dealer Demo"
				case "E": 	return "Enviro Series"
				case "F": 	return "Feedlot Series"
				case "H": 	return "Hydrostatic"
				case "M": 	return "Mechanical"
				case "O": 	return ""
				case "P": 	return "Prototype"
				case "Z": 	return "Supreme Demo"
				default: 	return "Unknown"
			}
		},
		getNewSize(size, typeLength){
			if (size == "24" || size == "20"){
				return size + " Foot"
			}

			if (typeLength == 3){
				return size + "50"
			} else {
				return size + "00"
			}
		},
		getOldSize(size) {
			switch(size){
				case "23":		return "2310"
				case "25":		return "2520"
				case "30": 		return "300"
				case "3": 		return "300"
				case "3S": 		return "300"
				case "35":		return "3570"
				case "38":		return "38 Series"
				case "40": 		return "400"
				case "4": 		return "400"
				case "4S": 		return "400"
				case "50": 		return "500"
				case "5": 		return "500"
				case "5S": 		return "500"
				case "60": 		return "600"
				case "6": 		return "600"
				case "6S": 		return "600"
				case "70": 		return "700T"
				case "79":		return "790"
				case "80": 		return "800T"
				case "90": 		return "900T"
				case "10":		return "1000T"
				case "12": 		return "1200T"
				case "14": 		return "1400T"
				case "15": 		return "1500TR"
				case "16": 		return "1600T"
				case "17": 		return "1700TR"
				case "18": 		return "18"
				case "19": 		return "1900TR"
				case "5T": 		return "500T"
				case "6T": 		return "600T"
				case "7T": 		return "700T"
				case "8T": 		return "800T"
				case "20": 		return "20'"
				case "24": 		return "24'"
				//case "24PT":	return "24'"
				default: 		return "Unknown"
			}
		},
		decode(ser) {
			this.clear()
			this.serial = ser.trim()


			if (this.isCurrentVersion()) {
				this.decodeCurrent()
			} else {
				this.decodeOld()
			}
		},
		isCurrentVersion() {
			var workingSerial = this.serial.toUpperCase()
			var length = workingSerial.length 
			var test = workingSerial.slice(0, 3)

			if(test == "SEG" || test == "10T" || test == "12T" || test == "14T")
				return true
			
			test = workingSerial.slice(0, 1)
			if(test == "0" || workingSerial.includes("A"))
				return false

			test = workingSerial.slice(length - 1, length)
			if(!this.isNumeric(test))
				return false
			
			test = workingSerial.slice(0, 4)
			if(test == "15TR" || test == "17TR" || test == "19TR")
				return true

			if(workingSerial.includes("TM3") || workingSerial.includes("TSM") || workingSerial.includes("0DB") || workingSerial.includes("4DB") || workingSerial.includes("DBPT") || workingSerial.includes("TRSM") || workingSerial.includes("TSH"))
				return true

			test = workingSerial.slice(0, 2)
			if (test == "3S" || test == "4S" || test == "5S" || test == "5T" || test == "6S" ||  test == "5T" || test == "6T" || test == "7T" || test == "8T" || test == "9T")
				return true
			
			return false
		},
		isNumeric(str) {
  		if (typeof str != "string") return false // we only process strings!  
  			return !isNaN(str) && !isNaN(parseFloat(str))
		},
		decodeCurrent() {
			var workingSerial = this.serial.toUpperCase()
			var length = workingSerial.length 

			// Parse out unit family
			var test = workingSerial.slice(0, 3)

			if (test == "SEG"){
				this.family = "Segue"
				workingSerial = workingSerial.slice(3)
				this.log = workingSerial
			} else {
				this.family = "Supreme"
			}

			// Parse last 2 characters for unit sequence
			length = workingSerial.length
			test = workingSerial.slice(length - 3, length)
			workingSerial = workingSerial.replace(test, "")
			this.sequence = this.getSequence(test)

			// Parse last 2 characters for year
			length = workingSerial.length
			test = workingSerial.slice(length - 2, length)
			workingSerial = workingSerial.replace(test, "")
			this.year = "20" + test

			test = workingSerial.match(/[a-zA-Z]+|[0-9]+/g)
			// Get unit Type
			if (test.length > 2){
				this.type = this.getType(test[1] + test[2])	
			} else {
				this.type = this.getType(test[1])
			}

			// Get unit size
			this.size = this.getNewSize(test[0], test.length)
		},
		decodeOld() {
			var workingSerial = this.serial.toUpperCase()
			var length = workingSerial.length 
			var test = workingSerial.slice(length - 1, length)

		// SUBTYPE (All letters on the end starting after last digit)
			if (!this.isNumeric(test)){
				test = workingSerial.match(/[a-zA-Z]+|[0-9]+/g)
				var match = test[test.length - 1]
				var length = match.length

				for (var i = 0; i < length; i++){
					if (i > 0){
						this.subType += ", "
					}

					this.subType += this.getSubType(match[i])
					//console.log("Subtype: " + this.getSubType(match[i]))
				}
				
				workingSerial = workingSerial.replace(match, "")
			}

		// ANNIVERSARY EDITION ("A" and every character after)
			if (workingSerial.includes("A")){
				length = workingSerial.length
				test = workingSerial.slice(workingSerial.indexOf("A"), length)

				if (this.subType.length > 0){
					this.subType += ", "
				}

				this.subType += "Anniversary Edition"
				workingSerial = workingSerial.replace(test, "")
			}

		// YEAR (first and last remaining characters)
			length = workingSerial.length
			test = workingSerial[0] + workingSerial.slice(length - 1, length)

			if (test[0] == "9"){
				this.year = "19" + test
			} else {
				this.year = "20" + test
			}

			workingSerial = workingSerial.slice(1).slice(0, -1)

		// FAMILY (First three chars are "SEG" or they aren't)
			if (workingSerial.includes("SEG")){
				this.family = "Segue"
				workingSerial = workingSerial.replace("SEG", "")
			} else {
				this.family = "Supreme"
			}

		// SEQUENCE (Last two characters)
			length = workingSerial.length
			test = workingSerial.slice(length -2, length)

			this.sequence = this.getSequence(test)
			workingSerial = workingSerial.slice(0, -2)

		// TYPE (First three characters if 3rd character isnt a number, otherwise first 2 characters)
			if(!this.isNumeric(workingSerial[2]) && workingSerial[2] != "S"){
				test = workingSerial.slice(0, 3)
				//this.subType = " "
			} else if (workingSerial[2] == "S"){
				test = workingSerial.slice(2, 4)
				//console.log("Third is an S")
			} else {
				test = workingSerial.slice(0, 2)
			}

			//console.log("Test Type: " + test)

			if (!this.isNumeric(test[0])){
				this.type = this.getType(test)
				workingSerial = workingSerial.replace(test, "")
			}

			//console.log("Decoded Type: " + this.type)
			//console.log("Remaining :" + workingSerial)

			if(this.type.length == 0){
					this.type = "Pull-Type"
			} else {
				if (this.subType.length == 0){
					//this.subType = "Hydrostatic"
				}

				// Catch Delivery Box Pull-Type
				if (workingSerial.includes("PT")){
					this.type = this.getType("DBPT")
				}

				test = workingSerial.slice(0, 2)
				
				if (test == "18" || test == "20" || test == "24"){
					this.subType = ""
				}
			}

			//console.log("Parse Unit Size.")

		// Parse unit size (remaining characters)
			this.size = this.getOldSize(workingSerial.slice(0, 2))

		// Catch SPSL
			if(this.type == "SPSL")
				this.size = this.size.slice(0,2) + "T"

		// Catch 2000HD
			if(this.type == "Triple Pull-Type" && this.size == "20'"){
				this.size = "2000HDT"
			}

		// Catch odd series segues
			if (this.size == "38 Series" || this.size == "2310" || this.size == "2520" || this.size == "3570" || this.size == "790"){
				this.family = "Segue"
			}

		// Catch Unknown size
			if(this.size == "Unknown"){
				this.family = ""
				this.type = ""
			}

		//Clear blank space subType
			this.subType = this.subType.trim()

			//console.log("Finished Sub-Type: " + this.subType)
		},
		clear() {
			this.serial = ""
			this.family = ""
			this.year = ""
			this.size = ""
			this.type = ""
			this.subType = ""
			this.sequence = ""
			this.log = ""
		}
	},
	mounted () {
		if (this.ser != null){
			this.decode(this.ser)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.serial-decoder {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.output {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.cells {
	margin-right: 15px;
	min-width: 150px;
}
</style>