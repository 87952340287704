<template>
	<div class="result-main">
		<div class="result-line"
			:style="getStyle()">
			<!-- Part Number Column -->
			<div class="result-part">
				<!-- BOM dropdown button -->
				<div 
					class="dropdown"
					ref="dropdown">
					<v-btn
						v-if="item.bom.length > 0"
						icon
						large
						@click="toggleBOM()">
						
						<v-icon
							v-if="!visibleBOM"
							large
							color="primary">
							mdi-arrow-right-drop-circle
						</v-icon>   

						<v-icon
							v-if="visibleBOM"
							large
							color="success">
							mdi-arrow-down-drop-circle
						</v-icon>    
					</v-btn>
				</div>

				<!-- Part Number display -->
				<div class="part-number">
					<!-- Small preview image in front of part number -->
					<v-tooltip
						right
						color="rgba(0,0,0,0)">
						<template v-slot:activator="{ on, attrs }">
							<div													
								v-bind="attrs"
								v-on="on">
								<img
									class="image-preview" 
									:src="imageAddress(item.image)" 
									height="100px" 
									width="100px"
									@click="partClicked(item.id, item.part)"/>
							</div>
						</template>

						<!-- Tooltip Pop-Up -->
						<div class="column">
							<!-- Part and description tooltip display -->
							<span 
								:style="sellableStyle(item.sellable)">
								{{ item.part }} - {{ item.description }}
							</span>

							<!-- Part image tooltip display -->
							<img
								class="image-preview" 
								:src="imageAddress(item.image)" 
								height="500px" 
								width="500px" />

							<!-- Supersedes and Sellable Status Tooltip Display -->
							<div 
								class="border"
								v-if="item.sellable == 0 || item.supersedes.length > 0">
								<!-- Non-Sellable Part Display -->
								<div
									v-if="item.sellable == 0" 
									:style="sellableStyle(item.sellable)">
									*** Non-Purchaseable item. Check for supersedes or available sub-components. ***<br><br>
								</div>

								<!-- Supersedes Display -->
								<div
									:style="sellableStyle(1)"
									v-if="item.supersedes.length > 0">
									<br>
									
									<span class="title">
										Part is superseded by: 
									</span>
									
									<div 
										v-for="supersede, index in item.supersedes"
										:key="index">

										- {{supersede.part}}
									</div>
								</div>
							</div>

							<!-- Part notes tooltip display if notes are set -->
							<div
								class="border" 
								v-if="item.notes != null && item.notes.length > 0">
								<span class="title" style="color:black; text-align: left; width: 100%;">Parts Notes:</span>

								<v-divider />

								<div 
									class="notes-preview">
									<span v-html="item.notes"></span>
								</div>
							</div>
						</div>
					</v-tooltip>

					<!-- Part number and supersede display -->
					<div class="part-column">
						<!-- Part number and tooltip display -->
						<v-tooltip
							right
							color="rgba(0,0,0,0)">
							<template v-slot:activator="{ on, attrs }">
								<span													
									v-bind="attrs"
									v-on="on" 
									:style="sellableStyle(item.sellable)"
									@click="partClicked(item.id, item.part)">
									{{ item.part }}
								</span>
							</template>

							<!-- Tooltip Pop-Up -->
							<div class="column">
								<!-- Part and description tooltip display -->
								<span 
									:style="sellableStyle(item.sellable)">
									{{ item.part }} - {{ item.description }}
								</span>

								<!-- Part image tooltip display -->
								<img
									class="image-preview" 
									:src="imageAddress(item.image)" 
									height="500px" 
									width="500px" />

								<!-- Supersedes and Sellable Status Tooltip Display -->
								<div 
									class="border"
									v-if="item.sellable == 0 || item.supersedes.length > 0">
									<!-- Non-Sellable Part Display -->
									<div
										v-if="item.sellable == 0" 
										:style="sellableStyle(item.sellable)">
										*** Non-Purchaseable item. Check for supersedes or available sub-components. ***<br><br>
									</div>

									<!-- Supersedes Display -->
									<div
										:style="sellableStyle(1)"
										v-if="item.supersedes.length > 0">
										<br>
										
										<span class="title">
											Part is superseded by: 
										</span>
										
										<div 
											v-for="supersede, index in item.supersedes"
											:key="index">

											- {{supersede.part}}
										</div>
									</div>
								</div>

								<!-- Part notes tooltip display if notes are set -->
								<div
									class="border" 
									v-if="item.notes != null && item.notes.length > 0">
									<span class="title" style="color:black; text-align: left; width: 100%;">Parts Notes:</span>

									<v-divider />

									<div 
										class="notes-preview">
										<span v-html="item.notes"></span>
									</div>
								</div>
							</div>
						</v-tooltip>

						<!-- Supersedes title -->
						<span 
							style="font-size: small;margin-left: 5px;"
							v-if="item.supersedes.length > 0">
							Superseded By: 
						</span>

						<!-- supersedes Display -->
						<div class="part-row">
							<div 
								v-for="supersede, index in item.supersedes"
								:key="index">
								<v-tooltip
									right
									color="rgba(0,0,0,0)">
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
											@click="partClicked(supersede.id, supersede.part)">
											<span v-if="index > 0">,&nbsp;</span>	
											<span :style="`${sellableStyle(supersede.sellable)}font-size: smaller;`">{{ supersede.part }}</span>
										</div>
									</template>

									<div class="column">
										<!-- Part description display if sellable -->
										<span 
											v-if="supersede.sellable"
											style="font-size: 14pt;color: black; max-width: 1000px;">
											{{ supersede.part }} - {{ supersede.description }}
										</span>

										<!-- Part description display if non-sellable -->
										<span
											v-if="!supersede.sellable"
											style="font-size: 14pt;color: red; max-width: 1000px;">
											{{ supersede.part }} is not available for purchase.
										</span>

										<!-- Part image display if an image is set -->
										<img
											v-if="supersede.image.length > 0"
											class="image-preview" 
											:src="imageAddress(supersede.image)" 
											height="500px" 
											width="500px" />

										<!-- Part image display if no image is set -->
										<img
											v-if="supersede.image.length == 0"
											class="image-preview" 
											src="@/assets/No_Image_Available.jpg" 
											height="500px" 
											width="500px" />

										<!-- Part notes display if notes are set -->
										<div
											class="border" 
											v-if="supersede.notes != null && supersede.notes.length > 0">
											<span style="font-size: 14pt;color:black; text-align: left; width: 100%;">Parts Notes:</span>

											<v-divider />

											<div 
												class="notes-preview">
												<span v-html="supersede.notes"></span>
											</div>
										</div>
									</div>								
								</v-tooltip>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Quantity Column -->
			<div class="result-quantity centered">
				<span style="width: 100%; text-align: center;">
					{{ item.quantity }}
				</span>
			</div>
			
			<!-- Description Column -->
			<div class="result-description">
				<table>
					<tr>
						<td>
							<!-- Item is sellable -->
							<div
								v-if="item.sellable == 1">
								<span v-html="item.description"></span>
							</div>

							<!-- Item is unsellable set description to red -->
							<div
								class="discontinued"
								v-if="item.sellable == 0">
								<span v-html="item.description"></span>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div 
								class="notes"
								v-if="item.notes != null && item.notes.length > 0">
								<span v-html="item.notes"></span>
							</div>
						</td>	
					</tr>
				</table>
			</div>
			
			<!-- Last Edited Column -->
			<div 
				class="result-edited centered">
				<span style="width: 100%; text-align: center;">
					{{ item.edited }}
				</span>
			</div>
		</div>

		<!-- Handle Recursive BOM -->
		<v-expand-transition v-show="visibleBOM">
			<div v-if="visibleBOM">
				<div
					v-for="category, index in subCategories"
					:key="category.id + '_' + index">

					<div v-if="category.results.length > 0">
						<!-- Show results of the current category -->
						<div v-if="category.id != 'DEFAULT'">
							<!-- Sub category header line -->
							<div 
								class="result-line"
								:style="getCategoryStyle()"
								v-if="category.id != 'DEFAULT'">
								<!-- Part Number Column -->
								<div class="result-part">
									<!-- BOM dropdown button -->
									<div
										class="dropdown" 
										:style="categoryIndent">
										<v-btn
											v-if="category.results.length > 0"
											icon
											large
											@click="toggleCategory(category)">
											
											<v-icon
												color="success"
												large
												v-if="!category.categoryVisible">
												mdi-arrow-right-drop-circle
											</v-icon>    
											<v-icon
												color="success"
												large
												v-if="category.categoryVisible">
												mdi-arrow-down-drop-circle
											</v-icon>    
										</v-btn>
									</div>

									<!-- Part Number Logic and display -->
									<div class="part-number">
										{{ category.name }}
									</div>
								</div>

								<!-- Quantity Column -->
								<div class="result-quantity centered">
								</div>
								
								<!-- Description Column -->
								<div class="result-description">
									<div style="height: 100%; width: 100%; padding: 5px; display: flex; align-items: center;">
										USE DROPDOWN ON THE LEFT TO EXPAND SUB-CATEGORY
									</div>
								</div>
								
								<!-- Last Edited Column -->
								<div class="result-edited centered">
								</div>
							</div>

							<v-expand-transition v-show="category.categoryVisible">
								<div v-if="category.categoryVisible">
									<component
										:is="'Result'"
										v-for="result, index in category.results"
										:key="result.part + '_' + index"
										:item="result"
										:level="nextLevel + 1"
										:nextLevel="nextLevel + 2">
									</component>
								</div>
							</v-expand-transition>

						</div>

						<div v-if="category.id == 'DEFAULT'">
							<component
								:is="'Result'"
								v-for="result, index in category.results"
								:key="result.part + '_' + index"
								:item="result"
								:level="nextLevel"
								:nextLevel="nextLevel + 1">
							</component>
						</div>
					</div>
				</div>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
export default {
	name: 'Result',
	components: {
	},
	props: {
		item: Object,
		level: Number,
		nextLevel: Number
	},
	data () {
		return {
			visibleBOM: false,
			subCategories: [],
		}
	},
	methods:{
		getConfig(){
			fetch('/config.json')
				.then((response) => response.json())
					.then((config) => {
						this.subCategories = config.serial.subCategories
						this.sortBOM()
					})			
		},
		getStyle() {
			if(this.level == 0) {
				return "background-color: rgba(0,0,0,0.05);"
			}

			if (this.level > 0) {
				var red = 132 //+ (this.level + 20)
				var green = 183 //+ (this.level + 20)
				var blue = 204 //+ (this.level + 20)
				var alpha = .95 - (0.15 * this.level)
				var style = `background-color: rgba(${red},${green}, ${blue}, ${alpha});`

				return style
			}

			return ""
		},
		getCategoryStyle() {
			if (this.level >= 0) {
				var red = 132 //+ (this.level + 20)
				var green = 183 //+ (this.level + 20)
				var blue = 204 //+ (this.level + 20)
				var alpha = .8 - (0.15 * this.level)
				var style = `background-color: rgba(${red},${green}, ${blue}, ${alpha});`

				return style
			}

			return ""
		},
		toggleBOM() {
			this.visibleBOM = !this.visibleBOM
		},
		toggleCategory(category) {
			category.categoryVisible = !category.categoryVisible
		},
		sortBOM() {
			// Clear previous category results
			for (var category of this.subCategories) {
				category.results = []
			}

			// If item has a BOM, Iterate through.
			if (this.item.bom != null && this.item.bom.length > 0){
				for (var bomPart of this.item.bom) {
					var found = false

					// Check all categories for a match
					for (var category of this.subCategories) {
						if (bomPart.category == category.id) {
							category.results.push(bomPart)
							found = true
						}
					}

					// No valid sub-category found add to default
					if (!found) {
						this.subCategories[0].results.push(bomPart)
					}
					
					// Reset found boolean no matter the result.
					found = false
				}
			}
		},
		partClicked(id, part) {
			if (part.length > 0) {
				this.$parent.partClicked(id, part)
			}
		},
		sellableStyle(sellable) {
			if (sellable == 1) {
				return "color: black;font-size: 14pt;width: 100%;max-width: 1000px;cursor: pointer;margin-left: 5px;"
			}

			return "color: red;font-size: 14pt;Width: 100%;max-width: 1000px;cursor: pointer;margin-left: 5px;"
		},		
		imageAddress(image) {
			if (image.length > 0){
				return `${this.$config.images}${image}`
			}

			return `${this.$config.images}No_Image_Available.jpg`
		},
	},
	computed: {
		hasImage() {
			if(this.item.image != null && this.item.image.length > 0){
				return true
			}

			return false
		},
		sellable(){
			if (this.item.sellable == 1){
				return true
			}

			return false
		},
		hasSupersede() {
			if(this.item.supersedes != null && this.item.supersedes.length > 0){
				return true
			}

			return false
		},
		categoryIndent() {
			return `width: ${this.$refs.dropdown.clientWidth + 20}px;`
		},
	},
	mounted () {
		if (this.level > 0){
			this.$refs.dropdown.style.width = this.$refs.dropdown.clientWidth + (20 * this.level) + "px"
		}

		this.subCategories = [...new Set(this.$config.serial.subCategories.map(JSON.stringify))].map(JSON.parse)
		
		this.sortBOM()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.result-row {
		display:flex;
		flex-direction: column;
		width: 100%;
		min-height: 25px;
		font-weight: 600;
	}

	.result-main {
		display:flex;
		flex-direction: column;
		width: 100%;
		min-height: 25px;
		font-weight: 600;
	}

	.result-line {
		display:flex;
		flex-direction: row;
		width: 100%;
		min-height: 25px;
		background-color: 80b3ff;
	}

	.result-part {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 30%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.dropdown {
		min-width: 40px;
		text-align: right;
	}

	.result-quantity {
		display: flex;
		align-items: center;
		width: 5%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-description {
		display: flex;
		text-align: left;
		width: 55%;
		border: 1px solid #4d4d4d;
	}

	.inner-description {
		width: 100%;
		padding: 5px;
		display: flex; 
		align-items: center;
	}

	.result-edited {
		display: flex;
		align-items: center;
		width: 10%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.centered {
		text-align: center;
	}

	.title {
		font-size: 24pt;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.discontinued {
		color: red;
	}

	.version {
		font-size: x-small;
	}

	.category-title {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
	}

	.expand-button {
		display: flex;
		align-items: center;
		margin-right: 10px;
		margin-left: 5px;
	}

	.expand-all-button {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.top-expand {
		display: flex;
		flex-direction: row;
		padding-right: 5px;
		padding-bottom: 5px;
	}

	.parts-line {
		display: flex;
		flex-direction: column;
	}

	.part-number {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.image-preview {
		max-height: 500px;
		max-width: 500px;
		object-fit: contain;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 2px solid darkgrey; 
		border-radius: 10px;
		padding: 5px;
		background-color: white;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.notes-preview {
		border: 1px solid darkgrey;
		border-radius: 5px;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		min-height: 100px;
		max-height: 300px;
		background-color: lightyellow;
		color: black;
		font-size: 18pt;
		max-width: 1000px;
	}

	.notes {
		border: 1px solid black;
		border-left: 2px solid black;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		max-height: 60px;
		min-height: 30px;
		background-color: lightyellow;
		color: black;
		font-size: 12pt;
		font-weight: 400;
	}

	.part-column {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.part-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
</style>