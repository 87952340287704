<template>
	<div id="app" ref="window">
		<v-app>
			<v-main class="main-window">
				<core />
			</v-main>
		</v-app>
	</div>
</template>

<script>
import Core from './components/core'

export default {
	name: 'App',
	components: {
		Core
	},
	data () {
		return {
		}
	},
	computed: {
	},
	methods: {
	},
	mounted () {
	}
}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: black;
		overflow: hidden;
	}

	.horizontal-center{
		margin-left: auto;
		margin-right: auto;
	}

	.center{
		margin-left: auto;
		margin-right: auto;
		margin-top: auto;
		margin-bottom: auto;
	}

	.mr5 {
		margin-right: 5px;
	}

	body::-webkit-scrollbar {
			display: none;
	}

	p {
		margin: 0px;
	}

	html {
		scrollbar-width: none;
	}

	.ck-editor__editable_inline {
			min-height: 300px;
			max-height: 300px;
			text-align: left;
	}

	.nowrap {
		white-space: nowrap;
	}

	.rot180 {
		transform: rotate(180deg);
	}

	.raisedcard {
		padding: 5px;
		width: 100%;
		height: 100%;
		border: 1px solid #bbb;
		border-radius: 5px;
		box-shadow: 2px 2px 2px rgba(60, 60, 60, 0.3);
	}

	.border {
		border: 1px solid silver; 
		border-radius: 5px;
		width: 100%;
		padding: 5px;
	}
</style>